<template>
  <div>
    <Navbar/>
    <div class="container mt-5">
      <router-link class="back-button " to="/"> &lt; BACK </router-link>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
        class="mt-5"
      >
        <strong style="background: transparent !important;"
          ><span style="background: transparent !important; font-size: 26px;"
            ><span
              data-custom-class="title"
              style="background: transparent !important; font-family: Arial !important; font-size: 26px !important; color: rgb(0, 0, 0) !important;"
              >COOKIE POLICY</span
            ></span
          ></strong
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><strong style="background: transparent !important;"
            ><span style="background: transparent !important; font-size: 15px;"
              ><span
                data-custom-class="subtitle"
                style="background: transparent !important; font-family: Arial !important; color: rgb(89, 89, 89) !important; font-size: 14px !important;"
                >Last updated&nbsp;December 08, 2021</span
              ></span
            ></strong
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px;"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >This Cookie Policy explains how&nbsp;JAB Companies,
              Inc.&nbsp;(&quot;<strong
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >Company</strong
              >&quot;, &quot;<strong
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >we</strong
              >&quot;, &quot;<strong
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >us</strong
              >&quot;, and &quot;<strong
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >our</strong
              >&quot;) uses cookies and similar technologies to recognize you
              when you visit our websites at&nbsp;<a
                data-custom-class="link"
                href="http://www.vaxtraxglobal.com/"
                style="background: transparent !important; color: rgb(48, 48, 241) !important; font-size: 14px !important; font-family: Arial !important; word-break: break-word !important;"
                target="_blank"
                >http://www.vaxtraxglobal.com</a
              >,&nbsp;(&quot;<strong
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >Websites</strong
              >&quot;). It explains what these technologies are and why we use
              them, as well as your rights to control our use of them.</span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px;"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >In some cases we may use cookies to collect personal information,
              or that becomes personal information if we combine it with other
              information.</span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(0, 0, 0); font-size: 15px;"
            ><strong style="background: transparent !important;"
              ><span
                data-custom-class="heading_1"
                style="background: transparent !important; font-family: Arial !important; font-size: 19px !important; color: rgb(0, 0, 0) !important;"
                >What are cookies?</span
              ></strong
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px;"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >Cookies are small data files that are placed on your computer or
              mobile device when you visit a website. Cookies are widely used by
              website owners in order to make their websites work, or to work
              more efficiently, as well as to provide reporting
              information.</span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px;"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >Cookies set by the website owner (in this case,&nbsp;JAB
              Companies, Inc.) are called &quot;first party cookies&quot;.
              Cookies set by parties other than the website owner are called
              &quot;third party cookies&quot;. Third party cookies enable third
              party features or functionality to be provided on or through the
              website (e.g. like advertising, interactive content and
              analytics). The parties that set these third party cookies can
              recognize your computer both when it visits the website in
              question and also when it visits certain other websites.</span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(0, 0, 0); font-size: 15px;"
            ><strong style="background: transparent !important;"
              ><span
                data-custom-class="heading_1"
                style="background: transparent !important; font-family: Arial !important; font-size: 19px !important; color: rgb(0, 0, 0) !important;"
                >Why do we use cookies?</span
              ></strong
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px;"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >We use first&nbsp;and third&nbsp;party cookies for several
              reasons. Some cookies are required for technical reasons in order
              for our Websites to operate, and we refer to these as
              &quot;essential&quot; or &quot;strictly necessary&quot; cookies.
              Other cookies also enable us to track and target the interests of
              our users to enhance the experience on our Online
              Properties.&nbsp;Third parties serve cookies through our Websites
              for advertising, analytics and other purposes.&nbsp;This is
              described in more detail below.</span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px;"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >The specific types of first&nbsp;and third&nbsp;party cookies
              served through our Websites and the purposes they perform are
              described below (please note that the specific cookies served may
              vary depending on the specific Online Properties you visit):</span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px;"
            ><span
              style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
              ><span
                style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
                ><span
                  style="background: transparent !important; color: rgb(0, 0, 0);"
                  ><strong style="background: transparent !important;"
                    ><span
                      data-custom-class="heading_1"
                      style="background: transparent !important; font-family: Arial !important; font-size: 19px !important; color: rgb(0, 0, 0) !important;"
                      >How can I control cookies?</span
                    ></strong
                  ></span
                ></span
              ></span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >You have the right to decide whether to accept or reject cookies.
              You can exercise your cookie rights by setting your preferences in
              the Cookie Consent Manager. The Cookie Consent Manager allows you
              to select which categories of cookies you accept or reject.
              Essential cookies cannot be rejected as they are strictly
              necessary to provide you with services.</span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >The Cookie Consent Manager can be found in the notification
              banner and on our website. If you choose to reject cookies, you
              may still use our website though your access to some functionality
              and areas of our website may be restricted. You may also set or
              amend your web browser controls to accept or refuse cookies. As
              the means by which you can refuse cookies through your web browser
              controls vary from browser-to-browser, you should visit your
              browser&apos;s help menu for more information.</span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >In addition, most advertising networks offer you a way to opt out
              of targeted advertising. If you would like to find out more
              information, please visit&nbsp;</span
            ></span
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(48, 48, 241);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              ><a
                data-custom-class="link"
                href="http://www.aboutads.info/choices/"
                style="background: transparent !important; color: rgb(48, 48, 241) !important; font-size: 14px !important; font-family: Arial !important; word-break: break-word !important;"
                target="_blank"
                >http://www.aboutads.info/choices/</a
              ></span
            ></span
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >&nbsp;or&nbsp;</span
            ></span
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(48, 48, 241);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              ><a
                data-custom-class="link"
                data-fr-linked="true"
                href="http://www.youronlinechoices.com/"
                style="background: transparent !important; color: rgb(48, 48, 241) !important; font-size: 14px !important; font-family: Arial !important; word-break: break-word !important;"
                target="_blank"
                >http://www.youronlinechoices.com</a
              ></span
            ></span
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >.</span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >The specific types of first and third party cookies served
              through our Websites and the purposes they perform are described
              in the table below (please note that the specific&nbsp;</span
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >cookies served may vary depending on the specific Online
              Properties you visit):</span
            ></span
          ></span
        ><span
          data-custom-class="heading_2"
          style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0);"
          ><span
            style="background: transparent !important; font-family: Arial !important; font-size: 15px; color: rgb(0, 0, 0) !important;"
            ><strong
              style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0) !important;"
              ><u
                style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0) !important;"
                ><br
                  style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0) !important;"
                /><br
                  style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0) !important;"
                />Essential website cookies:</u
              ></strong
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
      >
        <div style="background: transparent !important;">
          <br style="background: transparent !important;" />
        </div>
        <div style="background: transparent !important; line-height: 1.5;">
          <span
            style="background: transparent !important; color: rgb(127, 127, 127);"
            ><span
              style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px;"
              ><span
                data-custom-class="body_text"
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >These cookies are strictly necessary to provide you with
                services available through our Websites and to use some of its
                features, such as access to secure areas.</span
              ></span
            ></span
          >
        </div>
        <p
          style="background: transparent !important; font-size: 15px; line-height: 1.5;"
        >
          <br />
        </p>
        <section
          data-custom-class="body_text"
          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; width: 778px; border: 1px solid rgb(230, 230, 230); margin: 0px 0px 10px; border-radius: 3px; text-align: left;"
        >
          <div
            style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; padding: 8px 13px; border-bottom: 1px solid rgb(230, 230, 230);"
          >
            <table
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
            >
              <tbody
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >
                <tr
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                >
                  <td
                    style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                  >
                    Name:
                  </td>
                  <td
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                  >
                    <span
                      style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                      >__tlbcpv</span
                    >
                  </td>
                </tr>
                <tr
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px; vertical-align: top;"
                >
                  <td
                    style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                  >
                    Purpose:
                  </td>
                  <td
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                  >
                    <span
                      style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                      >Used to record unique visitor views of the consent
                      banner.</span
                    >
                  </td>
                </tr>
                <tr
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                >
                  <td
                    style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                  >
                    Provider:
                  </td>
                  <td
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                  >
                    <span
                      style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                      >.termly.io</span
                    >
                  </td>
                </tr>
                <tr
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                >
                  <td
                    style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                  >
                    Service:
                  </td>
                  <td
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                  >
                    <span
                      style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                      >Termly&nbsp;<a
                        href="https://termly.io/our-privacy-policy/"
                        style="background: transparent !important; color: rgb(26, 152, 235) !important; font-size: 14px !important; font-family: Arial !important;"
                        target="_blank"
                        >View Service Privacy Policy</a
                      >
                      &nbsp;</span
                    >
                  </td>
                </tr>
                <tr
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                >
                  <td
                    style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                  >
                    Country:
                  </td>
                  <td
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                  >
                    <span
                      style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                      >United States</span
                    >
                  </td>
                </tr>
                <tr
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                >
                  <td
                    style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                  >
                    Type:
                  </td>
                  <td
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                  >
                    <span
                      style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                      >http_cookie</span
                    >
                  </td>
                </tr>
                <tr
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                >
                  <td
                    style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                  >
                    Expires in:
                  </td>
                  <td
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                  >
                    <span
                      style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                      >1 year</span
                    >
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </section>
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
      >
        <br />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          data-custom-class="heading_2"
          style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0);"
          ><span
            style="background: transparent !important; font-family: Arial !important; font-size: 15px; color: rgb(0, 0, 0) !important;"
            ><strong
              style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0) !important;"
              ><u
                style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0) !important;"
                ><br
                  style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0) !important;"
                />Performance and functionality cookies:</u
              ></strong
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
      >
        <p
          style="background: transparent !important; font-size: 15px; line-height: 1.5;"
        >
          <span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >These cookies are used to enhance the performance and
              functionality of our Websites but are non-essential to their use.
              However, without these cookies, certain functionality (like
              videos) may become unavailable.</span
            ></span
          >
        </p>
        <div style="background: transparent !important;">
          <span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
            >
              <section
                data-custom-class="body_text"
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; width: 778px; border: 1px solid rgb(230, 230, 230); margin: 0px 0px 10px; border-radius: 3px;"
              >
                <div
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; padding: 8px 13px; border-bottom: 1px solid rgb(230, 230, 230);"
                >
                  <table
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                  >
                    <tbody
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                    >
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Name:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >AWSALBCORS</span
                          >
                        </td>
                      </tr>
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Purpose:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >For continued stickiness support with CORS use
                            cases after the Chromium update, we are creating
                            additional stickiness cookies for each of these
                            duration-based stickiness features named AWSALBCORS
                            (ALB).</span
                          >
                        </td>
                      </tr>
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Provider:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >api.aws.parking.godaddy.com</span
                          >
                        </td>
                      </tr>
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Service:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >Amazon Web Services&nbsp;<a
                              href="https://aws.amazon.com/privacy/"
                              style="background: transparent !important; color: rgb(26, 152, 235) !important; font-size: 14px !important; font-family: Arial !important;"
                              target="_blank"
                              >View Service Privacy Policy</a
                            >
                            &nbsp;</span
                          >
                        </td>
                      </tr>
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Country:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >United States</span
                          >
                        </td>
                      </tr>
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Type:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >server_cookie</span
                          >
                        </td>
                      </tr>
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Expires in:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >7 days</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <section
                data-custom-class="body_text"
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; width: 778px; border: 1px solid rgb(230, 230, 230); margin: 0px 0px 10px; border-radius: 3px;"
              >
                <div
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; padding: 8px 13px; border-bottom: 1px solid rgb(230, 230, 230);"
                >
                  <table
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                  >
                    <tbody
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                    >
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Name:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >AWSALB</span
                          >
                        </td>
                      </tr>
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Purpose:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >These cookies enable us to allocate server traffic
                            to make the user experience as smooth as possible. A
                            so-called load balancer is used to determine which
                            server currently has the best availability. The
                            information generated cannot identify you as an
                            individual.</span
                          >
                        </td>
                      </tr>
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Provider:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >api.aws.parking.godaddy.com</span
                          >
                        </td>
                      </tr>
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Service:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >Amazon Web Services&nbsp;<a
                              href="https://aws.amazon.com/privacy/"
                              style="background: transparent !important; color: rgb(26, 152, 235) !important; font-size: 14px !important; font-family: Arial !important;"
                              target="_blank"
                              >View Service Privacy Policy</a
                            >
                            &nbsp;</span
                          >
                        </td>
                      </tr>
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Country:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >United States</span
                          >
                        </td>
                      </tr>
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Type:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >http_cookie</span
                          >
                        </td>
                      </tr>
                      <tr
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                      >
                        <td
                          style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                        >
                          Expires in:
                        </td>
                        <td
                          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                        >
                          <span
                            style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                            >7 days</span
                          >
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
            </span></span
          >
        </div>
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          data-custom-class="heading_2"
          style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0);"
          ><span
            style="background: transparent !important; font-family: Arial !important; font-size: 15px; color: rgb(0, 0, 0) !important;"
            ><strong
              style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0) !important;"
              ><u
                style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0) !important;"
                ><br
                  style="background: transparent !important; font-family: Arial !important; font-size: 17px !important; color: rgb(0, 0, 0) !important;"
                />Unclassified cookies:</u
              ></strong
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial;"
      >
        <p
          style="background: transparent !important; font-size: 15px; line-height: 1.5;"
        >
          <span
            style="background: transparent !important; color: rgb(127, 127, 127);"
            ><span
              style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px;"
              ><span
                data-custom-class="body_text"
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >These are cookies that have not yet been categorized. We are in
                the process of classifying these cookies with the help of their
                providers.</span
              ></span
            ></span
          >
        </p>
        <div style="background: transparent !important;">
          <span
            data-custom-class="body_text"
            style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
          >
            <section
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; width: 778px; border: 1px solid rgb(230, 230, 230); margin: 0px 0px 10px; border-radius: 3px;"
            >
              <div
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; padding: 8px 13px; border-bottom: 1px solid rgb(230, 230, 230);"
              >
                <table
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >
                  <tbody
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                  >
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Name:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >traffic_target</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Purpose:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Provider:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >www.vaxtraxglobal.com</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Service:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________ &nbsp;</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Country:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >United States</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Type:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >server_cookie</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Expires in:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >1 day</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; width: 778px; border: 1px solid rgb(230, 230, 230); margin: 0px 0px 10px; border-radius: 3px;"
            >
              <div
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; padding: 8px 13px; border-bottom: 1px solid rgb(230, 230, 230);"
              >
                <table
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >
                  <tbody
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                  >
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Name:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >https://api.aws.parking.godaddy.com/v1/parking/landers/www.vaxtraxglobal.com?trafficTarget=gd</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Purpose:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Provider:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >www.vaxtraxglobal.com</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Service:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________ &nbsp;</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Country:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >United States</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Type:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >html_local_storage</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Expires in:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >persistent</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; width: 778px; border: 1px solid rgb(230, 230, 230); margin: 0px 0px 10px; border-radius: 3px;"
            >
              <div
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; padding: 8px 13px; border-bottom: 1px solid rgb(230, 230, 230);"
              >
                <table
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >
                  <tbody
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                  >
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Name:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >pwvisitor</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Purpose:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Provider:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >api.aws.parking.godaddy.com</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Service:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________ &nbsp;</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Country:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >United States</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Type:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >server_cookie</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Expires in:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >30 days</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; width: 778px; border: 1px solid rgb(230, 230, 230); margin: 0px 0px 10px; border-radius: 3px;"
            >
              <div
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; padding: 8px 13px; border-bottom: 1px solid rgb(230, 230, 230);"
              >
                <table
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >
                  <tbody
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                  >
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Name:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >caf_ipaddr</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Purpose:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Provider:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >www.vaxtraxglobal.com</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Service:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________ &nbsp;</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Country:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >United States</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Type:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >http_cookie</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Expires in:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >1 day</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; width: 778px; border: 1px solid rgb(230, 230, 230); margin: 0px 0px 10px; border-radius: 3px;"
            >
              <div
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; padding: 8px 13px; border-bottom: 1px solid rgb(230, 230, 230);"
              >
                <table
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >
                  <tbody
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                  >
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Name:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >country</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Purpose:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Provider:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >www.vaxtraxglobal.com</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Service:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________ &nbsp;</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Country:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >United States</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Type:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >http_cookie</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Expires in:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >1 day</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; width: 778px; border: 1px solid rgb(230, 230, 230); margin: 0px 0px 10px; border-radius: 3px;"
            >
              <div
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; padding: 8px 13px; border-bottom: 1px solid rgb(230, 230, 230);"
              >
                <table
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >
                  <tbody
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                  >
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Name:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >city</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Purpose:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Provider:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >www.vaxtraxglobal.com</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Service:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________ &nbsp;</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Country:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >United States</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Type:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >http_cookie</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Expires in:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >1 day</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
            <section
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; width: 778px; border: 1px solid rgb(230, 230, 230); margin: 0px 0px 10px; border-radius: 3px;"
            >
              <div
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; padding: 8px 13px; border-bottom: 1px solid rgb(230, 230, 230);"
              >
                <table
                  style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >
                  <tbody
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                  >
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Name:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >system</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Purpose:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Provider:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >www.vaxtraxglobal.com</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Service:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >__________ &nbsp;</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Country:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >United States</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Type:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >http_cookie</span
                        >
                      </td>
                    </tr>
                    <tr
                      style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 12px; font-family: Roboto, Arial; line-height: 1.67; margin: 0px 0px 8px; vertical-align: top;"
                    >
                      <td
                        style="background: transparent !important; color: rgb(25, 36, 60); font-size: 14px !important; font-family: Arial !important; text-align: right; min-width: 80px;"
                      >
                        Expires in:
                      </td>
                      <td
                        style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important; display: inline-block; margin-left: 5px;"
                      >
                        <span
                          style="background: transparent !important; color: rgb(139, 147, 167); font-size: 14px !important; font-family: Arial !important; word-break: break-all;"
                          >1 day</span
                        >
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </section>
          </span>
        </div>
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(0, 0, 0); font-size: 15px;"
            ><strong style="background: transparent !important;"
              ><span
                data-custom-class="heading_1"
                style="background: transparent !important; font-family: Arial !important; font-size: 19px !important; color: rgb(0, 0, 0) !important;"
                >What about other tracking technologies, like web beacons?</span
              ></strong
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px;"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >Cookies are not the only way&nbsp;</span
            ><span
              style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
              ><span
                data-custom-class="body_text"
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >to recognize or track visitors to a website. We may use other,
                similar technologies from time to time, like web beacons
                (sometimes called &quot;tracking pixels&quot; or &quot;clear
                gifs&quot;). These are tiny graphics files that contain a unique
                identifier that enable us to recognize when someone has visited
                our Websites&nbsp;or opened an e-mail including them. This
                allows us, for example, to monitor&nbsp;</span
              ><span
                style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
                ><span
                  style="background: transparent !important; color: rgb(89, 89, 89);"
                  ><span
                    data-custom-class="body_text"
                    style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                    >the traffic patterns of users from one page within a
                    website to another, to deliver or communicate with cookies,
                    to understand whether you have come to the website from an
                    online advertisement displayed on a third-party website, to
                    improve site performance, and to measure the success of
                    e-mail marketing campaigns. In many instances, these
                    technologies are reliant on cookies to function properly,
                    and so declining cookies will impair their
                    functioning.</span
                  ></span
                ></span
              ></span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px;"
            ><span
              style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
              ><span
                style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
                ><span
                  style="background: transparent !important; color: rgb(0, 0, 0);"
                  ><strong style="background: transparent !important;"
                    ><span
                      data-custom-class="heading_1"
                      style="background: transparent !important; font-family: Arial !important; font-size: 19px !important; color: rgb(0, 0, 0) !important;"
                      >Do you use Flash cookies or Local Shared Objects?</span
                    ></strong
                  ></span
                ></span
              ></span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            data-custom-class="body_text"
            style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
            >Websites may also use so-called &quot;Flash Cookies&quot; (also
            known as Local Shared Objects or &quot;LSOs&quot;) to, among other
            things, collect and store information about your use of our
            services, fraud prevention and for other site operations.</span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            data-custom-class="body_text"
            style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
            >If you do not want Flash Cookies stored on your computer, you can
            adjust the settings of your Flash player to block Flash Cookies
            storage using the tools contained in the&nbsp;</span
          ></span
        ><span
          data-custom-class="body_text"
          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
          ><span
            style="background: transparent !important; color: rgb(48, 48, 241); font-size: 14px !important; font-family: Arial !important;"
            ><a
              data-custom-class="link"
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html"
              style="background: transparent !important; color: rgb(48, 48, 241) !important; font-size: 14px !important; font-family: Arial !important; word-break: break-word !important;"
              target="_BLANK"
              ><span
                style="background: transparent !important; color: rgb(48, 48, 241) !important; font-size: 15px; font-family: Arial !important; word-break: break-word !important;"
                >Website Storage Settings Panel</span
              ></a
            ></span
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px; font-family: Arial !important;"
            >. You can also control Flash Cookies by going to the&nbsp;</span
          ><span
            style="background: transparent !important; color: rgb(48, 48, 241); font-size: 14px !important; font-family: Arial !important;"
            ><a
              data-custom-class="link"
              href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager03.html"
              style="background: transparent !important; color: rgb(48, 48, 241) !important; font-size: 14px !important; font-family: Arial !important; word-break: break-word !important;"
              target="_BLANK"
              ><span
                style="background: transparent !important; color: rgb(48, 48, 241) !important; font-size: 15px; font-family: Arial !important; word-break: break-word !important;"
                >Global Storage Settings Panel</span
              ></a
            ></span
          ></span
        ><span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            data-custom-class="body_text"
            style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
            >&nbsp;and&nbsp;</span
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >following the instructions (which may include instructions that
              explain, for example, how to delete existing Flash Cookies
              (referred to &quot;information&quot; on the Macromedia site), how
              to prevent Flash LSOs from being placed on your computer without
              your being asked, and (for Flash Player 8 and later) how to block
              Flash Cookies that are not being delivered by the operator of the
              page you are on at the time).</span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >Please note that setting the Flash Player to restrict or limit
              acceptance of Flash Cookies may reduce or impede the functionality
              of some Flash applications, including, potentially, Flash
              applications used in connection with our services or online
              content.</span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; color: rgb(127, 127, 127);"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px;"
            ><span
              style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
              ><span
                style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
                ><span
                  style="background: transparent !important; color: rgb(89, 89, 89);"
                  ><span
                    style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
                    ><span
                      style="background: transparent !important; font-size: 15px; color: rgb(0, 0, 0);"
                      ><strong style="background: transparent !important;"
                        ><span
                          data-custom-class="heading_1"
                          style="background: transparent !important; font-family: Arial !important; font-size: 19px !important; color: rgb(0, 0, 0) !important;"
                          >Do you serve targeted advertising?</span
                        ></strong
                      ></span
                    ></span
                  ></span
                ></span
              ></span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            data-custom-class="body_text"
            style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
            >Third parties may serve cookies on your computer or mobile device
            to serve advertising through our Websites. These companies may use
            information about your visits to this and other websites in order to
            provide relevant advertisements about goods and services that you
            may be interested in. They may also employ technology that is used
            to measure the effectiveness of advertisements. This can be
            accomplished by them using cookies or web beacons to collect
            information about your visits to this and other sites in order to
            provide relevant advertisements about goods and services of
            potential interest to you. The information collected through this
            process does not enable us or them to identify your name, contact
            details or other details that directly identify you unless you
            choose to provide these.</span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(0, 0, 0);"
            ><strong style="background: transparent !important;"
              ><span
                data-custom-class="heading_1"
                style="background: transparent !important; font-family: Arial !important; font-size: 19px !important; color: rgb(0, 0, 0) !important;"
                >How often will you update this Cookie Policy?</span
              ></strong
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >We may update&nbsp;</span
            ><span
              style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
              ><span
                data-custom-class="body_text"
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >this Cookie Policy from time to time in order to reflect, for
                example, changes to the cookies we use or for other operational,
                legal or regulatory reasons. Please therefore re-visit this
                Cookie Policy regularly to stay informed about our use of
                cookies and related technologies.</span
              ></span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
              ><span
                data-custom-class="body_text"
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >The date at the top of this Cookie Policy indicates when it was
                last updated.</span
              ></span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              style="background: transparent !important; font-size: 15px; color: rgb(0, 0, 0);"
              ><strong style="background: transparent !important;"
                ><span
                  data-custom-class="heading_1"
                  style="background: transparent !important; font-family: Arial !important; font-size: 19px !important; color: rgb(0, 0, 0) !important;"
                  >Where can I get further information?</span
                ></strong
              ></span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <br style="background: transparent !important;" />
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
              ><span
                data-custom-class="body_text"
                style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
                >If you have any questions about our use of cookies or other
                technologies, please email us at&nbsp;daveb@jabcomp.com&nbsp;or
                by post to:</span
              ></span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          data-custom-class="body_text"
          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
          ><br
            style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
        /></span>
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
            ><span
              data-custom-class="body_text"
              style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
              >JAB Companies, Inc.</span
            ></span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          style="background: transparent !important; font-size: 15px; color: rgb(89, 89, 89);"
          ><span
            data-custom-class="body_text"
            style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
            >1405 Silver Lake Rd NW, New Brighton, MN 55112, USA</span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          data-custom-class="body_text"
          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px; font-family: Arial !important;"
            >New Brighton,&nbsp;MN&nbsp;55112</span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          data-custom-class="body_text"
          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px; font-family: Arial !important;"
            >United States</span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          data-custom-class="body_text"
          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px; font-family: Arial !important;"
            >Phone:&nbsp;(+1)6123380794</span
          ></span
        >
      </div>
      <div
        style="background: transparent !important; color: rgb(0, 0, 0); font-family: Roboto, Arial; font-size: medium; font-style: normal; font-variant-ligatures: normal; font-variant-caps: normal; font-weight: 400; letter-spacing: normal; orphans: 2; text-align: start; text-indent: 0px; text-transform: none; white-space: normal; widows: 2; word-spacing: 0px; -webkit-text-stroke-width: 0px; text-decoration-thickness: initial; text-decoration-style: initial; text-decoration-color: initial; line-height: 1.5;"
      >
        <span
          data-custom-class="body_text"
          style="background: transparent !important; color: rgb(89, 89, 89) !important; font-size: 14px !important; font-family: Arial !important;"
          ><span
            style="background: transparent !important; color: rgb(89, 89, 89); font-size: 15px; font-family: Arial !important;"
            >Fax: (+1)6123384136</span
          ></span
        >
      </div>
    </div>
    <!-- <Footer /> -->
  </div>
</template>

<script>
import Navbar from "@/components/Navbar.vue";

export default {
  components: {
    Navbar
  }, mounted() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  },
};
</script>

<style scoped>
.back-button {
  background: rgb(134, 237, 255);
  color: rgb(7, 10, 10);
  font-size: 1.5rem;
  line-height: 1;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 0.2s;
  border-radius: 20px;
  box-shadow: 0px 5px 10px rgb(216, 215, 215);
}

.back-button:hover {
  background: rgba(10, 138, 155, 0.8);
  color: #fff;
}
</style>
